import React from 'react';
import { HashRouter, Switch, Route } from 'react-router-dom';
import { Row, Spin } from 'antd';
import { Provider } from 'jotai';

import 'antd/dist/antd.css';
import '../shared/styles/global.css'

const TheLayout = React.lazy(() => import('../containers/TheLayout'));

function App() {
  return (
    <Provider>
      <HashRouter>
        <React.Suspense fallback={<Row justify='center'><Spin size='large' /></Row>}>
          <Switch>
            <Route render={props => <TheLayout {...props} />} />
          </Switch>
        </React.Suspense>
      </HashRouter>
    </Provider>
  );
}

export default App;
